import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface IframeVideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ApplicationIframe
}

const StyledBannerDefault = styled.div`
  background-color: ${(props) => props.theme.color.face.main};

  @media screen and (max-width: 576px) {
    height: 200px;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};
  
`

const IframeVideoDefault: React.FC<IframeVideoProps> = ({ fields }) => (
  <StyledBannerDefault>
    <div className="container h-100 position-relative py-5">
      <Content content={fields.iframeUrl} />
    </div>
  </StyledBannerDefault>
)

export default IframeVideoDefault
